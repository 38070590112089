import Carousel from "react-bootstrap/Carousel";
import green from "../images/gray6.png";
import blog from "../images/blog4-min.jpg";
import spqr from "../images/spqr.png";
import paper from "../images/paper3.png";
import vid from "../images/P1_Gold.mp4";
import vid2 from "../images/video.mp4";
import vid3 from "../images/P3_Gold.mp4";
import Image from "react-bootstrap/Image";
const Projects = () => {
  return (
    <Carousel slide={false}>
      <Carousel.Item className="first-item">
        <div>
          <Image
            src={green}
            className="first-pic w-100"
            alt="rushil saha"
            loading="lazy"
          />
          <Image
            className="second-pic"
            src={blog}
            alt="rushil saha"
            loading="lazy"
          />
        </div>
        <Carousel.Caption>
          <div className="caption">
            <h3>Blog Application</h3>
            <p>
              I wanted to build a full stack project from scratch so that I
              could gain experience building both the front and back end of an
              app, as well as connecting the two together. As a result, I
              settled on developing a blog application using React, Relay,
              SQLite, and GraphQL that would show a set of blogs of that users
              could interact with through commenting and likes. I decided to
              store the blog data (names, posts, dates) in a SQLlite database
              and then fetch data using GraphQL. It was very challenging
              learning how to use GraphQL and Relay in the beginning to display
              data on the front end, but finally getting it to work was a very
              rewarding experience that made me want to explore more.{" "}
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="first-item">
        <div>
          <Image
            className="first-pic w-100"
            src={green}
            alt="rushil saha"
            loading="lazy"
          />
          <Image
            className="second-pic"
            src={spqr}
            alt="rushil saha"
            loading="lazy"
          />
        </div>
        <Carousel.Caption>
          <div className="caption">
            <h3>SPQR Research Blog</h3>
            <p>
              I joined the SPQR Lab at UMich freshman year, which is a lab that
              works on really cool embedded security topics. I worked as a blog
              writer, covering the different research topics that they discussed
              during their meetings. This was an extremely cool experience as I
              got exposed to a number of really interesting (but also really
              frightening) attacks that exist out there. One attack that stood
              out in particular was creating false traffic signals like stop
              signals on billboards that would cause autonomous cars to react as
              if there actually as a stop sign. I really enjoyed taking the time
              to write weekly blog entries about fun and interesting research
              that I encoutered. I definitely want to explore more computer
              science writing opportinities in the future.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="first-item">
        <div>
          <Image
            className="first-pic w-100"
            src={green}
            alt="rushil saha"
            loading="lazy"
          />
          <Image
            className="second-pic"
            src={paper}
            alt="rushil saha"
            loading="lazy"
          />
        </div>
        <Carousel.Caption>
          <div className="caption">
            <h3>Computer Vision Research</h3>
            <p>
              I took a Computer Vision course at UMich sophomore year, which
              required a research project as the final for the class. Me and my
              team members were inspired by the Hot dog or Not Hot dog app that
              was discussed in the show Silicon Valley. As a result, we decided
              to work on a similar project conducting food classification using
              a CNN. We decided to base the model off of AlexNet, which is a
              popular architecture, but tweaked some of the layers as we tuned
              the model. We also applied several image filters like grayscale
              before inputting them into the model to see if they would improve
              model accuracy. This project was very time consuming, but finally
              cooking a burger at the end of the project and seeing the model
              identify it correctly made it all worth it.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="first-item">
        <div>
          <Image
            className="first-pic w-100"
            src={green}
            alt="rushil saha"
            loading="lazy"
          />
          <video className="second-pic" width="500" height="600" controls>
            <source src={vid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <Carousel.Caption>
          <div className="captionExtra">
            <h3>CSE Lab Simulator</h3>
            <p>
              I took a capstone class called EECS 498 on VR/AR technologies
              senior year at UMich. As part of this class, my partner and I were
              tasked with creating a VR simulation of a computer lab on campus
              using Unreal Engine, Jira, and Blueprints. It was a very daunting
              task getting familiar with the dev environment, but it became
              really fun to work on. We were given a lot of creative freedom
              with adding interactions and objects which was really exciting.
              The VR simulation contains an interactive simulation of the lab
              where you can walk around, talk to NPC's, pull a fire alarm, throw
              trash away, as well as a few other features.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="first-item">
        <div>
          <Image
            className="first-pic w-100"
            src={green}
            alt="rushil saha"
            loading="lazy"
          />
          <video className="second-pic" width="500" height="600" controls>
            <source src={vid2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <Carousel.Caption>
          <div className="captionExtra">
            <h3>A2-Go!</h3>
            <p>
              As part of the capstone class EECS 498 in VR/AR at UMich, me and
              my partner had to create an AR application called A2-Go!. The goal
              of the project was to create an AR application that would allow
              people to explore Ann Arbor, finding different landmarks and using
              currency to grow trees. We developed the project in Unity and used
              Jira for project management. We were able to create an immersive
              experience where users can both travel around using the map and
              interact with objects through the camera on their phones. We also
              added a custom feature where users can learn more about the
              history of specific landmarks by opening up a website when the
              button is pressed.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className="first-item">
        <div>
          <Image
            className="first-pic w-100"
            src={green}
            alt="rushil saha"
            loading="lazy"
          />
          <video className="second-pic" width="500" height="600" controls>
            <source src={vid3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <Carousel.Caption>
          <div className="captionExtra">
            <h3>Meta-Museum</h3>
            <p>
              My team and I developed a VR application called Meta-Museum as the
              final project for the capstone class EECS 498 in VR/AR at UMich.
              The goal of the project was to create an immersive VR experience
              that replicated the same in person museum experience. There are
              four main exhibits (Japanese, Egyptian, Greek/Roman, Medieval)
              that all contain relevant artifacts and corresponding historical
              descriptions. We also added additional features to the experience
              like trivia questions, mini games, and interactable NPC's. Users
              can collect museum passes by interacting with objects to unlock
              new parts of the museum. We developed the project using Unreal
              Engine, Jira, and Sketchfab.
            </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Projects;
