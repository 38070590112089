import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const Navigation= () => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Toggle className="ms-auto" />
                <Navbar.Collapse>
                    <Nav className="nav ms-auto">
                        <Nav.Link href="/" className="mx-auto" >home</Nav.Link>
                        <Nav.Link href="/about" className="mx-auto" >about</Nav.Link>
                        <Nav.Link href="/projects" className="mx-auto" >projects</Nav.Link>
                        <Nav.Link href="/contact" className="mx-auto" >contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

};

export default Navigation;