import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/Navbar'
import Projects from './components/Projects'
import React from 'react';
import { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  const Home = lazy(() => import('./components/Home'));
  const About = lazy(() => import('./components/About'));
  const Contact = lazy(() => import('./components/Contact'));

  return (
    <Router>
      <Suspense>
        <Navigation />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </Router>
    
  );
}

export default App;
